<template>
  <div class="bidding__wrapper">
    <div class="commom_title">
      <a-row>
        <a-col :span="16">
          <a-row>
            <a-col :span="1">
              <div class="commom_line"></div>
            </a-col>
            <a-col :span="6">视频</a-col>
          </a-row>
        </a-col>
      </a-row>
    </div>
    <div class="info">
      <a-row>
        <a-col :span="24">
          <div class="Title">
            <div class="header-box">
              <h1 style="font-weight: 600">{{ videoInfo.name }}</h1>
              <!-- <div class="type_item">
                <img
                  @click="like"
                  v-if="isLike"
                  src="../../../public/like_select.png"
                  style="width: 30px; height:  30px; "
                />
                <img
                  @click="like"
                  v-else
                  src="../../../public/like.png"
                  style="width: 30px; height:  30px; "
                />
                <div
                  class="type_item_title"
                  :style="{color:isLike?'#4D72F7':'#878D95'}"
                >
                  <p>喜欢</p>
                  <p>1000000</p>
                </div>
              </div> -->
            </div>
            <div class="Title_item">
              <p class="member">会员专享</p>
              <p class="Type">{{ videoInfo.typeString }}</p>
            </div>
            <div class="Infocontent">
              {{ videoInfo.subheading }}
            </div>
          </div>
          <div style="padding: 0px 20px">
            <video
              style="width: 100%; height: 650px"
              ref="video"
              :poster="videoInfo.videoImageUrl"
              :src="videoInfo.videoUrl"
              controlslist="nodownload"
              @timeupdate="myFunction"
              controls
            ></video>
          </div>
          <a-row style="padding: 10px 20px">
            <a-col :span="4"> </a-col>
            <!-- <a-col :span="4">
              <div class="type_item">
                <img
                  @click="download"
                  v-if="isDownload"
                  src="../../../public/download_select.png"
                  style="width: 30px; height:  30px; "
                />
                <img
                  @click="download"
                  v-else
                  src="../../../public/download.png"
                  style="width: 30px; height:  30px; "
                />
                <div
                  class="type_item_title"
                  :style="{color:isDownload?'#4D72F7':'#878D95'}"
                >
                  <p>下载</p>
                  <p>1000000</p>
                </div>
              </div>
            </a-col> -->
          </a-row>
        </a-col>
        <!-- <a-col :span="8">
          <div style="background: #F1F2F3;border-radius: 4px;padding: 10px;font-weight: 600;font-size: 22px;">评论（124）</div>
          <div
            class="comment_item"
            ref="comment_item"
            @scroll="activityScroll"
          >
            <div
              v-for="(item,index) in List"
              :key="index"
            >
              <commentCard></commentCard>
            </div>
          </div>
        </a-col> -->
      </a-row>
      <a-modal title="试看结束提醒" :visible="promptFlag" width="1050px" @cancel="promptFlag = false">
        <template #footer></template>
        <div class="prompt-text">
          当前视频可免费观看<font color="red">15S</font> ，如需观看全部内容，建议您使用企业账号登录后查看。若您当前没有
          企业账号，也可以通过扫描下方二维码，下载“有好活APP”,注册登录成功后，观看全部内容。
        </div>
        <div class="model-img"><img src="@/assets/home/app-down.png" alt="" /></div>
        <div class="model-text">使用手机扫一扫下载安装有好活app</div>
        <div class="model-prompt">*ios用户可在app store 搜索安装应用</div>
      </a-modal>
    </div>
  </div>
</template>

<script>
import commentCard from '@/components/commentCard.vue'
import { getVideoDetails } from '@/api/video'
export default {
  components: {
    commentCard
  },
  data () {
    return {
      promptFlag: false, // 友情提示状态值
      show: false,
      List: [1, 1, 1, 1, 1, 1, 1, 1],
      Alist: [1, 1, 1],
      searchText: '',
      CurrentData: {
        path: 'http://qyvod.ruanwe.com/sv/3751aad4-1862fab56da/3751aad4-1862fab56da.mp4'
      },
      isLike: false,
      isPurchase: false,
      isDownload: false,
      videoInfo: {}
    }
  },
  created () {
    getVideoDetails(this.$route.query.id).then((res) => {
      this.videoInfo = res.data
    })
  },
  methods: {
    activityScroll () {
      var clientHeight = this.$refs.comment_item.clientHeight // 该容器的固定高度
      var scrollTop = this.$refs.comment_item.scrollTop // 滚动条距顶部的距离
      var scrollHeight = this.$refs.comment_item.scrollHeight // 内容的高度
      if (scrollHeight - scrollTop <= clientHeight) {
        this.List = this.List.concat(this.Alist)
      }
    },
    download () {
      if (this.isDownload) {
        this.isDownload = false
      } else {
        this.isDownload = true
      }
      console.log('下载')
    },
    like () {
      if (this.isLike) {
        this.isLike = false
      } else {
        this.isLike = true
      }
      console.log('喜欢')
    },
    myFunction (event) {
      if (event.target.currentTime > 15 && !this._isVip) {
        this.$refs.video.pause()
        this.$refs.video.currentTime = 15
        this.promptFlag = true
      }
    }
  }
}
</script>
<style lang="less">
@import url('../../common/style');
</style>
<style scoped lang="less">
.bidding__wrapper {
  margin: 30px;
  .header-box {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  .info {
    height: 100%;
    position: relative;

    .popup {
      position: relative;
      padding: 60px;
      text-align: center;

      image {
        position: absolute;
        top: -100px;
        left: 60px;
      }

      h4 {
        margin: 150px 0 40px 0;
      }

      p {
        color: #7d807f;
        font-size: 26px;
      }

      .button {
        display: inline-block;
        text-align: center;
        margin-top: 60px;
      }
    }

    .Title {
      padding: 20px;

      .Title_item {
        .member {
          color: #fff;
          background: #4d72f7;
          display: inline-block;
          text-align: center;
          font-size: 13px;
          margin: 0px 20px 0px 0px;
          padding: 6px;
        }

        .Type {
          font-size: 13px;
          padding: 6px;
          background: rgba(77, 114, 247, 0.1);
          color: #1677ff;
          display: inline-block;
        }
      }

      .Infocontent {
        margin-top: 10px;
        font-size: 16px;
        color: #aaaaaa;
        width: 100%;
      }
    }
    .type_item {
      display: flex;
      text-align: center;
      img {
        margin-top: 10px;
        cursor: pointer;
      }
      &_title {
        display: inline-block;
        p {
          font-size: 14px;
          margin-bottom: 0px;
        }
      }
    }
    .comment_item {
      height: 700px;
      overflow: auto;
      padding-bottom: 60px;
    }
  }
}
.prompt-text {
  width: 100%;
  text-align: center;
  font-size: 18px;
  margin: 0 auto;
  color: #000000;
}
.ant-modal-title {
  text-align: center;
  font-weight: 500;
  font-size: 18px;
  color: #000000;
}
.model-img {
  width: 220px;
  height: 220px;
  margin: 30px auto;
  img {
    width: 100%;
    height: 100%;
  }
}
.model-text {
  width: 100%;
  text-align: center;
  font-size: 18px;
  margin: 0 auto;
}
.model-prompt {
  width: 210px;
  height: 22px;
  font-family: 'PingFang SC';
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  margin: 4px auto;
  margin-bottom: 30px;
}
</style>
