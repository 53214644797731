<template>
  <div class="padding-wrap">
    <div class="comment-face">
      <img
        style="width:100%;height: 100%;"
        src="http://8.142.218.12:9000/test/safety-20221112-5e9ea56ecdf54670a5530a533d343ba0.png"
        mode="widthFix"/>
    </div>
    <div class="comment-body">
      <div class="comment-top">
        <span>用户id昵称</span>
      </div>
      <div class="comment-date">
        <span>2021/09/20 12:21:12</span>
      </div>
      <div class="comment-content">这里是评价详情这里是评价详情这里是评价详情这里是评价详情这里是评价详情这里是评价详情。</div>
    </div>
  </div>
</template>

<script>
	export default {
		data () {
			return {

			}
		}
	}
</script>

<style>
	/* comment */
	.padding-wrap {
		flex-wrap: nowrap;
		padding: 5px;
		width: 100%;
		display: flex;
		border-bottom: 1px dashed #F1F2F3;
	}

	.comment-face {
		width: 34px;
		height: 34px;
		border-radius: 100%;
		margin-right: 10px;
		flex-shrink: 0;
		overflow: hidden;
	}

	.comment-face image {
		width: 100%;
		border-radius: 100%;
	}

	.comment-top {
		font-size: 17px;
		color: #1A2135;
	}

	.comment-date {
		margin: 5px 0px;
		color: #878D95;
		font-size: 24rpx;
	}

	.comment-content {
		font-size: 14px;
	}
</style>
